.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-background {
  background: url('assets/white_carbon.webp') ;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.il-card {
  stroke: #111;
  stroke-width: 1px;
  fill-opacity: 0.2;
  fill: #ff3333;
}

.il-card text {
  fill: #000;
  font-family: "Verdana", sans-serif;
}

.play-button {
  background: rgba(25,65,235,0.9);
  width: fit-content;
  justify-content: center;
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  max-height: 3em;
}

.play-button-holder {
  padding-top: 0px;
  height: 0;
  width: 100vw;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
}

.red-fill {
  fill: #ff3333;
}

.blue-fill {
  fill: #3333ff;
}


text.highlighted {
  text-shadow: 10px 1px 20px yellow;
}

svg.good-match text.highlighted {
  text-shadow: 10px 1px 20px limegreen;
}

svg.bad-match text.highlighted {
  text-shadow: 10px 1px 20px orangered;
}

text {
  cursor:pointer;
}

@media print {
  @page {
    size: letter;
    margin: 1in 0.5in 1in 0.5in;
  }

  /*main.MuiBox-root {*/
  /*  padding: 12px*/
  /*}*/

  .ilControl, .play-button, header.MuiAppBar-root.App-header {
    display: none;
  }

  .ilPage {
    background-color: #fff;
  }

  .page-holder {
    background-color: #fff;
    height: 111.9in;
    width: 7.5in;
    stroke: black;
    stroke-width: 1px;
  }

  .break-after {
    page-break-after: always;
  }

  .il-card {
    stroke: #444;
    stroke-width: 2px;
    fill: #fff;
  }
}
